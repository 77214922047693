import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import { Link } from "gatsby"

import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "e_rmc_0110-large.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <div id="award-page">
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          id="hero-section"
          style={{ marginTop: `70px`, }}
        >
        </BackgroundImage>
          <div className="hero-textbox pad">
            <div id="award-hero-textbox">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12" style={{
                    maxWidth: `880px`,
                    marginLeft: `auto`,
                    marginRight: `auto`,
                  }}>
                    <h1 className="mb-5 semibold serif">Awards</h1>
                    {/* <p className="small-copy text-left ml-3 mr-3">This award honors the process of reporting and editing journalism as much as the final content. Judges will consider the time, resources and obstructions to reporting each nominee faced.</p> */}
                    <hr className="mb-0" />
                    <p className="h3 pt-5 pb-5 mb-0 semibold serif">Dan Rather Medals for Excellence in News and Guts</p>
                    <hr />
                    <div className="row pt-2 pb-2">
                      <div className="col-sm-12 col-md-6 borderright pl-5 pr-5 pt-4 pb-4">
                        <h3 className="h2 semibold">Professional</h3>
                        <p className="h3 serif mb-0">$5,000 Award</p>
                        <Link to="/apply/" className="small-link mb-5 d-inline-block">See Details</Link>
                        <p className="small-copy text-left">To be given to a journalist, team of journalists or a news organization producing an exceptional journalistic story or series in spite of facing demonstrable threats and hurdles – exhibiting news and guts.</p>
                        <p className="small-copy text-left">Examples include overcoming:</p>
                        <ul className="award-list small-copy text-left">
                          <li>Government resistance to the release of public records</li>
                          <li>Financial threats to coverage</li>
                          <li>Online harassment</li>
                        </ul>
                      </div>
                      <div className="col-sm-12 col-md-6 pl-5 pr-5 pt-4 pb-4">
                        <h3 className="h2 semibold">Student Media</h3>
                        <p className="h3 serif mb-0">$5,000 Award</p>
                        <Link to="/apply/" className="small-link mb-5 d-inline-block">See Details</Link>
                        <p className="small-copy text-left">To be given to a college journalist, a team of college journalists or college news organization producing an exceptional journalistic story or series whose execution required an exceptional reporting effort.</p>
                        <p className="small-copy text-left">The journalists produced the story in spite of:</p>
                        <ul className="award-list small-copy text-left">
                          <li>University or government resistance</li>
                          <li>Financial threats to coverage</li>
                          <li>University threats to coverage</li>
                          <li>Online harassment</li>
                        </ul>
                      </div>
                    </div>
                    <hr className="mt-4 mb-5" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }}
  />
)

const AwardStyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`

export default AwardStyledBackgroundSection
